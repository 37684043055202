import React, { useEffect, useRef, useState } from "react";
import * as powerbiClient from "powerbi-client";
import * as models from "powerbi-models";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { getContentPageById } from "../store/slices/contentPageSlice";
import {
  fetchEmbedToken,
  setEmbeddedReport,
} from "../store/slices/embedTokenSlice";
import CustomLoader from "../components/constants/CustomLoader";
import HeaderContainer from "../components/constants/HeaderContainer";
import "./report.css";
import { fetchWorkspaceContentPages } from "../store/slices/workspaceContentSlice";
const PowerBIReport: React.FC = () => {
  const embedContainer = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [rendered, setRendered] = useState(false);
  const { reportID } = useParams<{ reportID: string }>();
  const [currentPage, setCurrentPage] = useState<string>("");
  const { contentPage, loading } = useSelector(
    (state: RootState) => state.contentPages
  );
  const { contentPages } = useSelector(
    (state: RootState) => state.workspaceContents
  );
  const { embedToken, loading: embedLoading } = useSelector(
    (state: RootState) => state.embedToken
  );

  useEffect(() => {
    if (contentPages.length > 0 && contentPage?.ReportPages) {
      const pages = contentPage.ReportPages.split(";").map((page) =>
        page.trim()
      );
      const filteredPages = pages.filter((page) => page !== "all");
      setCurrentPage(filteredPages[0] || "");
    }
  }, [contentPages, contentPage]);

  useEffect(() => {
    if (reportID) {
      dispatch(getContentPageById(Number(reportID)));
    }
  }, [reportID, dispatch]);

  useEffect(() => {
    if (contentPage) {
      dispatch(
        fetchEmbedToken({
          groupId: contentPage.PowerBiWorkspace || "",
          reportId: contentPage.PagePath || "",
        })
      );
    }
  }, [contentPage, dispatch]);

  useEffect(() => {
    if (contentPage?.PowerBiWorkspace && contentPage?.PagePath) {
      dispatch(
        fetchWorkspaceContentPages({
          groupId: contentPage?.PowerBiWorkspace,
          reportId: contentPage?.PagePath,
        })
      );
    }
  }, [dispatch, contentPage?.PowerBiWorkspace, contentPage?.PagePath]);

  useEffect(() => {
    const embedPowerBIReport = async () => {
      const powerbi = window["powerbi"];
      if (!powerbi) {
        console.error("Power BI SDK is not loaded");
        return;
      }

      if (contentPage && embedToken) {
        const EmbedUrl = contentPage.EmbedUrl;
        const embedReportId = contentPage.PagePath;
        const tokenType = models.TokenType.Embed;
        const permissions: models.Permissions = models.Permissions.All;

        const config: models.IReportEmbedConfiguration = {
          type: "report",
          tokenType: tokenType,
          accessToken: embedToken.token,
          embedUrl: EmbedUrl,
          id: embedReportId,
          permissions: permissions,
          settings: {
            panes: {
              filters: {
                visible: contentPage.ShowFilter,
              },
              pageNavigation: {
                visible: false,
              },
            },
          },
          pageName: currentPage,
        };

        if (embedContainer.current) {
          const embeddedReport = powerbi.embed(
            embedContainer.current,
            config
          ) as powerbiClient.Report;
          dispatch(setEmbeddedReport(embeddedReport));

          embeddedReport.off("loaded");
          embeddedReport.on("loaded", function () {});

          embeddedReport.off("error");
          embeddedReport.on(
            "error",
            function (event: powerbiClient.service.ICustomEvent<any>) {
              console.error(event.detail);
            }
          );

          embeddedReport.off("rendered");
          embeddedReport.on("rendered", function () {
            setRendered(true);
          });
        }
      }
    };

    if (contentPage && embedToken) {
      embedPowerBIReport();
    }
  }, [contentPage, embedToken, dispatch, currentPage]);

  return (
    <div className="relative w-full h-full">
      {embedLoading || loading ? (
        <CustomLoader height={70} />
      ) : (
        <div className="w-full">
          {contentPage?.HideTitleAnddescription &&
          !contentPage.ShowContentPane ? (
            <div></div>
          ) : (
            <HeaderContainer
              contentPages={contentPages}
              title={
                contentPage?.HideTitleAnddescription
                  ? ""
                  : contentPage?.Title || ""
              }
              activeTab={currentPage}
              onTabClick={(tab) => setCurrentPage(tab)}
              description={contentPage?.Description || ""}
              tabs={
                (contentPage?.ShowContentPane &&
                  (() => {
                    const pages =
                      contentPage?.ReportPages?.split(";").map((page) =>
                        page.trim()
                      ) || [];

                    if (pages[0].toLowerCase() === "all" && pages.length > 1) {
                      return pages.filter(
                        (page) => page?.toLowerCase() !== "all"
                      );
                    } else if (
                      pages[0]?.toLowerCase() === "all" &&
                      pages.length <= 1
                    ) {
                      return contentPages.map((page) => page.name);
                    }

                    return pages;
                  })()) ||
                []
              }
            />
          )}
          <div
            className={`absolute right-0 left-0 ${
              !contentPage?.HideTitleAnddescription
                ? contentPage?.Title && contentPage?.Description
                  ? "top-20 bottom-0"
                  : contentPage?.ShowContentPane
                  ? "top-20 bottom-0"
                  : "top-10 bottom-0"
                : contentPage?.ShowContentPane
                ? "top-14 bottom-0"
                : "top-0 bottom-0"
            }`}
            id="embedContainer"
            ref={embedContainer}
          />
        </div>
      )}
    </div>
  );
};

export default PowerBIReport;
