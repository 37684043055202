import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { Dropdown, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./../../store/store";
import { fetchContentPages } from "./../../store/slices/contentPageSlice";
import logo from "./../../assets/images/logo.jpg";
import { BiMenu } from "react-icons/bi";
import { fetchBranding } from "../../store/slices/brandingSlice";
import BottomNavbar from "../header/BottomNavbar";
import { fetchTenants } from "../../store/slices/tenantSlice";
import { fetchUser } from "../../store/slices/userSlice";

const Sidebar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { contentPages, loading: contentPagesLoading } = useSelector(
    (state: RootState) => state.contentPages
  );
  const layouts = useSelector((state: RootState) => state.layouts.layouts);

  const [open, setOpen] = useState(true);
  const [submenuOpen, setSubmenuOpen] = useState<{ [key: number]: boolean }>(
    {}
  );

  const { tenants } = useSelector((state: RootState) => state.tenants);
  const { user } = useSelector((state: RootState) => state.users);

  useEffect(() => {
    dispatch(fetchTenants());
    dispatch(fetchUser());
  }, [dispatch]);

  const { branding } = useSelector((state: RootState) => state.branding);

  useEffect(() => {
    if (!branding) {
      dispatch(fetchBranding());
    }
  }, [dispatch, branding]);

  useEffect(() => {
    dispatch(fetchContentPages());
  }, [dispatch]);

  useEffect(() => {
    if (contentPagesLoading) return;

    if (contentPages.length === 0) {
      navigate("/reports/contentPage/OverviewPage");
    } else {
      const firstItem = contentPages.find(
        (page) => page.Parent === "" || page.Parent === null
      );

      if (firstItem) {
        navigate(`/reports/contentPage/${firstItem.id}`);
      } else {
        navigate("/reports/contentPage/OverviewPage");
      }
    }
  }, [contentPages]);

  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    const pathname = window.location.pathname;
    const activeTabFromPath = pathname.substring(21);
    setActiveTab(activeTabFromPath);
  }, [window.location.pathname]);

  const toggleSubmenu = (index: number) => {
    setSubmenuOpen((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div
      className={`bg-[var(--brand-primary-color)] h-screen ${
        open ? "w-80" : "w-20"
      } duration-300 relative`}
    >
      <BiMenu
        onClick={() => setOpen(!open)}
        className={`text-[var(--top-navigation-font)] hover:text-[var(--side-navigation-font-hover)] text-3xl absolute z-[100] -right-14 top-8
           cursor-pointer ${!open && "rotate-180"}`}
      />
      <div className="flex w-full gap-2 items-center p-5 pb-2 bg-[var(--top-navigation-panel-secondary)]">
        <div className="border-2 border-gray-600 rounded-full p-1">
          {open ? (
            <img
              src={branding?.logoImage ? branding?.logoImage : logo}
              width={35}
              alt="logo"
              className="rounded-full"
            />
          ) : (
            <img
              src={
                branding?.secondaryLogoImage
                  ? branding?.secondaryLogoImage
                  : logo
              }
              width={35}
              alt="logo"
              className="rounded-full"
            />
          )}
        </div>
        {open && (
          <div className="flex flex-col items-start text-[var(--side-navigation-header-font-color)]">
            {tenants
              .filter((item) => item.id === Number(user?.currentTenant))
              .map((item, index) => (
                <Dropdown.Item key={index}>
                  <span className="text-bold">
                    {item.tenantName === "superTenant"
                      ? "Super Tenant"
                      : item.tenantName}
                  </span>{" "}
                </Dropdown.Item>
              ))}
          </div>
        )}
      </div>
      <div className=""></div>

      <ul className="pt-2 p-5">
        {contentPages.length <= 0 && !contentPagesLoading && (
          <li
            className={`text-[var(--side-navigation-font-primary)] flex items-center gap-x-4 cursor-pointer my-1 p-2 rounded-md 
            ${
              activeTab === "OverviewPage"
                ? "bg-secondary"
                : "hover:bg-gray-500"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`contentPage/OverviewPage`);
            }}
          >
            <span className="text-l block float-left">
              <Icon name="home" />
            </span>
            <span
              className={`text-base font-medium flex-1 duration-200 ${
                !open && "hidden"
              }`}
            >
              Overview
            </span>
          </li>
        )}
        {contentPages
          .filter(
            (page) =>
              (page.Parent === "" || page.Parent === null) &&
              (page.type !== "category" ||
                contentPages.some(
                  (child) => child.Parent === page.id.toString()
                ))
          )
          ?.map((category, index) => (
            <React.Fragment key={index}>
              <li
                className={`text-[var(--side-navigation-font-primary)] flex items-center gap-x-4 cursor-pointer my-1 p-2 rounded-md 
                  ${
                    activeTab === category.id.toString()
                      ? "bg-[var(--side-navigation-panel-secondary)]"
                      : "hover:text-[var(--side-navigation-panel-secondary)]"
                  }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (category.type === "contentPage") {
                    navigate(`contentPage/${category.id}`);
                  } else {
                    toggleSubmenu(index);
                  }
                }}
              >
                <span className="text-l block float-left">
                  {/* @ts-ignore */}
                  <Icon name={category.Icon} />
                </span>
                <span
                  className={`text-base font-medium flex-1 duration-200 ${
                    !open && "hidden"
                  }`}
                >
                  {category.Title}
                </span>
                {contentPages.some(
                  (page) => page.Parent === category.id.toString()
                ) &&
                  open && (
                    <BsChevronDown
                      className={`${submenuOpen[index] && "rotate-180"}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleSubmenu(index);
                      }}
                    />
                  )}
              </li>
              {submenuOpen[index] && open && (
                <ul>
                  {contentPages
                    .filter((page) => page.Parent === category.id.toString())
                    .map((page, idx) => (
                      <li
                        key={idx}
                        className={`text-gray-300 text-sm flex items-center gap-x-4 my-1 cursor-pointer p-2 px-5  rounded-md ${
                          activeTab === page.id.toString()
                            ? "bg-[var(--side-navigation-panel-secondary)]"
                            : "hover:text-[var(--side-navigation-panel-secondary)]"
                        }`}
                        onClick={() => navigate(`contentPage/${page.id}`)}
                      >
                        <span className="text-lg block float-left">
                          {/* @ts-ignore */}
                          <Icon name={page.Icon} />
                        </span>
                        <span
                          className={`text-base font-medium flex-1 duration-200 ${
                            !open && "hidden"
                          }`}
                        >
                          {page.Title}
                        </span>
                      </li>
                    ))}
                </ul>
              )}
            </React.Fragment>
          ))}
      </ul>
      {layouts[0]?.layout === "Classic" && (
        <div className="w-full absolute bottom-0 left-0 right-0 mb-6">
          <BottomNavbar open={open} />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
