import React, { useEffect, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "./store/store";
import { fetchColors } from "./store/slices/colorSlice";
import { fetchBranding } from "./store/slices/brandingSlice";
import { fetchLayouts } from "./store/slices/layoutSlice";
import { Suspense } from "react";
import Signin from "./components/auth/Signin";
import Signup from "./components/auth/Signup";
import ReportPage from "./pages/ReportPage";
import SettingPage from "./pages/SettingPage";
import { ToasterProvider } from "./providers/toast-provider";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/CreatePassword";

const App = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { colors } = useSelector((state: RootState) => state.colors);
  const { layouts } = useSelector((state: RootState) => state.layouts);
  const { branding } = useSelector((state: RootState) => state.branding);

  const appStyles = useMemo(
    () => ({
      "--brand-primary-color": colors?.brandPrimaryColor || "#081C2E",
      "--side-navigation-panel-primary":
        colors?.sideNavigationPanelPrimary || "#081C2E",
      "--top-navigation-panel-primary":
        colors?.topNavigationPanelPrimary || "#ffffff",
      "--top-navigation-panel-secondary":
        colors?.topNavigationPanelSecondary || "#081C2E",
      "--top-navigation-font": colors?.topNavatigationFont || "#403A3A",
      "--side-navigation-panel-item-highlight":
        colors?.sideNavigationPanelItemHighlight || "#F0F0F0",
      "--side-navigation-font-hover":
        colors?.sideNavigationFontHover || "#C7C7C7",
      "--navigation-border-color": colors?.navigationBorderColor || "#D95558",
      "--report-pane-background": colors?.reportPaneBackground || "#FFFFFF",
      "--side-navigation-header-font-color":
        colors?.sideNavigationHeaderFontColor || "#FFFFFF",
      "--side-navigation-font-primary":
        colors?.sideNavigationFontPrimary || "#FFFFFF",
      "--side-navigation-panel-secondary":
        colors?.sideNavigationPanelSecondary || "#D95558",
      "--content-pane-titles": colors?.contentPaneTitles || "#D95558",
      "--button-face-color": colors?.buttonFaceColor || "#595959",
      "--navigation-arrow-color": colors?.navigationArrowColor || "#D95558",
      "--page-name-current-page": colors?.paneNameCurrentPage || "#F3F4F6",
    }),
    [colors]
  ) as React.CSSProperties;

  useEffect(() => {
    if (!colors) {
      dispatch(fetchColors());
    }
    if (!branding) {
      dispatch(fetchBranding());
    }
    if (!layouts.length) {
      dispatch(fetchLayouts());
    }
  }, [colors, branding, layouts.length, dispatch]);

  useEffect(() => {
    if (branding?.siteFaviconImage) {
      changeFavicon(branding.siteFaviconImage);
    }
  }, [branding]);

  const changeFavicon = (faviconUrl: string) => {
    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = faviconUrl;

    const existingFavicon: HTMLLinkElement | null =
      document.querySelector("link[rel*='icon']");
    if (existingFavicon) {
      document.head.removeChild(existingFavicon);
    }

    document.head.appendChild(link);
  };

  // const isDataLoading =
  //   brandingLoading || !branding || colorLoading || layoutLoading;

  // if (isDataLoading) {
  //   return (
  //     <div className="flex items-center justify-center h-screen">
  //       <CustomLoader height={70} />
  //     </div>
  //   );
  // }

  return (
    <div className="flex h-screen" style={appStyles}>
      <ToasterProvider />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/*" element={<Signin />}></Route>
          <Route path="/forgot" element={<ForgotPassword />}></Route>
          <Route
            path="/users/auth/createpassword/:token"
            element={<ResetPassword />}
          ></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/reports/*" element={<ReportPage />}></Route>
          <Route path="/settings/*" element={<SettingPage />}></Route>
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
